// @flow
import { ApolloLink } from '@apollo/client';
import Cookie from 'js-cookie';

const getAccessToken = () => Cookie.get('access_token');

/**
 * We don't have access token initially and ApolloClient doesn't update,
 * this way we can make sure to attach updated cookie to headers
 */
const authLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
        headers: getAccessToken()
            ? {
                  ...headers,
                  authorization: `Bearer ${getAccessToken()}`,
              }
            : headers,
    }));

    return forward(operation);
});

export default authLink;
