// @flow
import gql from 'graphql-tag';

export const QUERY_USER_DATA = gql`
    query userData {
        userData @client {
            jwt
        }
    }
`;
