import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { hydrateRoot } from 'react-dom/client';
import { ApolloProvider } from '@apollo/react-components';
import { loadableReady } from '@loadable/component';
import * as Sentry from '@sentry/browser';

import AppGQL from './graphql';

import setupApollo from './graphql/setupApollo';
import { isProductionBuild } from '@project/utils';

// NOTE: CSS Reset
import './styles/index.css';
import './styles/theme.less';

if (isProductionBuild()) {
    Sentry.init({
        dsn: process.env.RAZZLE_SENTRY_DSN,
        environment: process.env.RAZZLE_ENVIRONMENT,
    });
}

/* eslint-disable-next-line no-underscore-dangle */
const initialState = window.__STATE__;

/* eslint-disable-next-line no-underscore-dangle */
delete window.__STATE__;

const container = document.getElementById('root') as HTMLElement;

loadableReady(() => {
    hydrateRoot(
        container,
        <ApolloProvider client={setupApollo()}>
            <BrowserRouter>
                <AppGQL initialState={initialState} />
            </BrowserRouter>
        </ApolloProvider>
    );
});

if (module.hot) {
    module.hot.accept();
}
