// @flow
import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';

import * as Styled from './styledComponents';

import { isStaging, isProduction } from '@project/utils';

type Props = {
    children: React.ReactNode;
    isShowStopper?: boolean;
    text?: string;
};

type State = {
    hasError: boolean;
};

class ErrorBoundary extends Component<Props, State> {
    static defaultProps = {
        text: 'Oops! Something went wrong',
        isShowStopper: false,
    };

    state = {
        hasError: false,
    };

    componentDidCatch(error: any, info: GenericObject) {
        console.error(error, info);
        this.setState({ hasError: true });

        if (isStaging() || isProduction()) {
            Sentry.captureException(error);
        }
    }

    render() {
        const { hasError } = this.state;
        const { children, text, isShowStopper } = this.props;

        if (hasError) {
            if (isShowStopper) {
                return (
                    <Styled.WrapperFullScreen>
                        <Styled.ErrorLarge>{text}</Styled.ErrorLarge>
                    </Styled.WrapperFullScreen>
                );
            }
            return (
                <Styled.Wrapper>
                    <span>{text}</span>
                </Styled.Wrapper>
            );
        }

        return children;
    }
}

export default ErrorBoundary;
