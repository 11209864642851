import { ThemeConfig } from 'antd/es/config-provider/context';

const COLORS = {
    primary: '#0e2b36',
    primaryInactive: '#16323d',
    primaryHover: '#bcd966',
    secondary: '#9acd05',
    secondaryInactive: '#d7eb9b',
    secondaryHover: '#d7eb9b',
    accent: '#9acd05',
    accentInactive: '#d7eb9b',
    accentHover: '#d7eb9b',
    buttonLight: '#26404A',
    backgroundLight: '#f8f9fa',
    backgroundDark: '#cfd6de',
    description: '#3e555e',
    title: '#476772',
    textComplementary: '#759baa',
    label: '#9faaaf',
    labelInactive: '#d4d9db',
    border: '#e9eced',
    danger: '#ff3a3a',
    white: '#ffffff',
};

const theme: ThemeConfig = {
    ...COLORS,
    token: {
        colorPrimary: COLORS.secondary,
        colorPrimaryHover: '#bcd966',

        // controlItemBgHover: COLORS.secondary,
        // colorPrimaryBorder: COLORS.secondary,
        // colorBorder: COLORS.secondary,
        // colorBorderBg: COLORS.secondary,
        // colorBorderSecondary: COLORS.secondary,

        // colorPrimaryBorderHover: COLORS.secondary,
        // colorPrimaryActive: COLORS.secondary,

        // colorSuccessBorder: COLORS.secondary,
        // colorSuccess: COLORS.secondary,
        // colorSuccessHover: COLORS.secondary,
        // colorSuccessActive: COLORS.secondary,
    },
    //
    //     colorBgBase: COLORS.backgroundLight,
    //
    //     colorPrimaryBgHover: COLORS.buttonLight,
    //     controlItemBgHover: COLORS.buttonLight,
    //
    //     // any link
    //     colorLink: COLORS.secondary,
    //     colorLinkHover: COLORS.primaryHover,
    //
    //     // used in Menu
    //     colorBgTextHover: COLORS.backgroundLight,
    //     colorBgTextActive: COLORS.backgroundDark,
    //
    //     colorBgContainerDisabled: COLORS.labelInactive,
    //
    //     // colorError: COLORS.danger,
    //     // colorSuccess: COLORS.secondary,
    //     // colorPrimaryText: COLORS.primary,
    //     // colorText: COLORS.primary,
    // },
    // components: {
    //     Breadcrumb: {
    //         colorLink: COLORS.primary,
    //         colorLinkHover: COLORS.primaryHover,
    //     },
    // },
};

export default theme;
