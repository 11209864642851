import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

type Props = RouteProps & {
    isAuthenticated: boolean;
};

const RedirectAuthenticatedRoute = ({ isAuthenticated, ...props }: Props) => {
    if (isAuthenticated) {
        return <Redirect to="/properties" />;
    }

    return <Route {...props} />;
};

export default RedirectAuthenticatedRoute;
