// @flow
import { WebAuth } from 'auth0-js';
import Cookie from 'js-cookie';

import { isProductionBuild } from '@project/utils';

export type LoginCredentials = {
    email: string;
    password: string;
};

interface IAuth0Service {
    login: (data?: LoginCredentials, callback?: Function) => void;
    logout: () => void;
}

const Auth0: IAuth0Service = (() => {
    if (typeof window === 'undefined') {
        return {
            login: () => console.error('Cannot run login on serverside'),
            logout: () => console.error('Cannot run logout on serverside'),
        };
    }

    const auth0 = new WebAuth({
        domain: process.env.RAZZLE_AUTH0_DOMAIN,
        clientID: process.env.RAZZLE_AUTH0_CLIENT_ID,
        audience: process.env.RAZZLE_AUTH0_AUDIENCE,
        responseType: 'token id_token code',
        scope: 'openid profile email offline_access',
    });

    return {
        login: (credentials?: LoginCredentials, callback?: Function): void => {
            if (credentials) {
                auth0.client.login(
                    {
                        realm: 'Username-Password-Authentication',
                        username: credentials.email,
                        password: credentials.password,
                    },
                    (error, response) => {
                        if (error) {
                            console.error(`Authentication Error: ${error.description}`);
                            if (callback) callback(null, error);

                            return;
                        }

                        if (callback) callback(response);
                        Cookie.set('access_token', response.accessToken);
                    }
                );
            }
        },
        logout: () => {
            Cookie.remove('access_token');
            auth0.logout({
                returnTo: isProductionBuild()
                    ? process.env.RAZZLE_AUTH0_REDIRECT_URL
                    : `http://localhost:${process.env.PORT || 3000}`,
            });
        },
    };
})();

export default Auth0;
