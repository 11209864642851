// @flow
import { onError } from '@apollo/client/link/error';
import * as R from 'ramda';

import Auth from '../../services/Auth0';
import { isValidJSON } from '@project/utils';

/**
 * TODO: Connect with some error reporting Service
 */
const errorLink = onError(({ networkError }) => {
    if (networkError) {
        console.error(`[GraphQL Error]: ${networkError}`);

        if (networkError.result && networkError.result.errors) {
            console.group('[GraphQL Network Error]: Details:');
            networkError.result.errors.forEach((item, index) => {
                // `data.originalError` usually is some error type like `GraphQLError`
                const messagePrefix = `\t#${index}`;

                const errorData: string = item.message;

                /**
                 * ! Mutation Error message schema:
                 * ? Group #1 Basic error description (mostly useless)
                 * ? Group #2 JSON Data
                 * ? Group #3 Detailed error description
                 *
                 * ! Query Error Message
                 * ? Simple Error Description
                 */
                const matches = errorData.match(/(.+?)(\{.*\})(.+)/);

                // TODO: check it on more data if it works properly
                if (!matches) {
                    console.error(`${messagePrefix}: ${errorData}`);
                } else {
                    // Beautifying error detail
                    const errorDetails = matches[3].replace(';', '').trim();

                    if (isValidJSON(matches[2])) {
                        const parsedJSON = JSON.parse(matches[2]);
                        console.error(`${messagePrefix}: ${errorDetails}`, parsedJSON);
                    } else {
                        console.error(`${messagePrefix}: ${errorDetails}`, matches[2]);
                    }
                }
            });
            console.groupEnd();
        }
    }

    // Logout on any 401 error
    if (R.path(['response', 'status'], networkError) === 401) {
        Auth.logout();
    }
});

export default errorLink;
