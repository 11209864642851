// @flow
import { ApolloClient, InMemoryCache, from } from '@apollo/client';

import authLink from './links/authLink';
import errorLink from './links/errorLink';
import uploadLink from './links/uploadLink';
import { resolvers, defaults } from './resolvers';

import { QUERY_USER_DATA } from './queries';

const cache = new InMemoryCache().restore(window.__APOLLO_STATE__);

cache.writeQuery({ query: QUERY_USER_DATA, data: defaults });

// ! https://www.apollographql.com/docs/react/advanced/caching.html#normalization
const setupApollo = (): ApolloClient =>
    new ApolloClient({
        link: from([authLink, errorLink, uploadLink]),
        cache,
        resolvers,
    });

export default setupApollo;
