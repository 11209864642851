import React, { Component, createContext } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import Cookie from 'js-cookie';
import * as R from 'ramda';

import ErrorBoundary from '@project/components/ErrorBoundary';
import ProtectedRoute from '@project/components/ProtectedRoute';
import RedirectAuthenticatedRoute from '@project/components/RedirectAuthenticatedRoute';
import { Root, LoginPage, NotFound, ForgottenPassword } from './routes';

import theme from '@project/theme';
import { ConfigProvider, notification } from 'antd';

type Props = {
    updateUserData: (userData: GenericObject) => void;
    userData: GenericObject;
    jwt?: string;
    location: Location;
};

export const NotificationsContext = createContext({});

/**
 * @todo:
 * - Add jwt token validity check
 */
class App extends Component<Props, { notifications: string[] }> {
    state = {
        notifications: [],
    };

    componentDidMount() {
        const { updateUserData, jwt, userData } = this.props;

        // NEEDS TO BE HERE FOR HMR TO WORK
        if (!userData?.jwt) {
            updateUserData({ variables: { jwt } });
        }
    }

    UNSAFE_componentWillReceiveProps(props) {
        const { location } = this.props;
        if (props.location.pathname !== location.pathname) {
            this.state.notifications.forEach(key => notification.destroy(key));
            this.setState({ notifications: [] });
        }
    }

    onLogin = async (user: GenericObject) => {
        const { updateUserData } = this.props;

        /**
         * This is important as it takes same time before cookie is saved into the browser
         * we are interacting over the cookies and redirecting user to the app after it is saved
         * so all requests have got access to it
         */
        const waitForCookieToBeSaved = setInterval(() => {
            if (Cookie.get('access_token')) {
                updateUserData({ variables: { jwt: user.accessToken } });
                clearInterval(waitForCookieToBeSaved);
            }
        }, 10);
    };

    render() {
        const { userData, jwt } = this.props;

        const isAuthenticated = Boolean(jwt) || Boolean(R.path(['jwt'], userData));

        return (
            <NotificationsContext.Provider
                value={{
                    notifications: this.state.notifications,
                    addNotification: (newKey: string) =>
                        this.setState({ notifications: [...this.state.notifications, newKey] }),
                }}
            >
                <ThemeProvider theme={theme}>
                    <ConfigProvider theme={theme}>
                        <ErrorBoundary isShowStopper>
                            <Switch>
                                <Route path="/404" component={NotFound} />
                                <Route path="/forgotten-password" component={ForgottenPassword} />
                                <RedirectAuthenticatedRoute
                                    exact
                                    path="/login"
                                    render={props => (
                                        <LoginPage onLogin={this.onLogin} {...props} />
                                    )}
                                    isAuthenticated={isAuthenticated}
                                />
                                <ProtectedRoute
                                    path="/"
                                    component={Root}
                                    isAuthenticated={isAuthenticated}
                                />
                            </Switch>
                        </ErrorBoundary>
                    </ConfigProvider>
                </ThemeProvider>
            </NotificationsContext.Provider>
        );
    }
}

export default withRouter(App);
