import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

type Props = RouteProps & {
    isAuthenticated: boolean;
};

const ProtectedRoute = ({ isAuthenticated, ...props }: Props) => {
    if (!isAuthenticated) {
        return <Redirect to="/login" />;
    }

    return <Route {...props} />;
};

export default ProtectedRoute;
