// @flow
import gql from 'graphql-tag';

export const UPDATE_USER_DATA = gql`
    mutation updateUserData($jwt: string) {
        updateUserData(jwt: $jwt) @client
    }
`;

export const SAVE_MESSAGING_TOKEN = gql`
    mutation saveMessagingToken($token: String!) {
        saveMessagingToken(token: $token)
    }
`;
