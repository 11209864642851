// @flow
import React from 'react';
import { Query, Mutation } from '@apollo/react-components';
import * as R from 'ramda';

import App from './App';

import { QUERY_USER_DATA } from './graphql/queries';
import { UPDATE_USER_DATA } from './graphql/mutations';

type Props = {
    initialState: GenericObject;
};

const AppGQL = ({ initialState }: Props) => (
    <Mutation mutation={UPDATE_USER_DATA}>
        {updateUserData => (
            <Query query={QUERY_USER_DATA}>
                {({ data }) => (
                    <App
                        jwt={R.path(['user', 'jwt'], initialState)}
                        userData={data?.userData}
                        updateUserData={updateUserData}
                    />
                )}
            </Query>
        )}
    </Mutation>
);

export default AppGQL;
