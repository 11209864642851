// @flow
import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const WrapperFullScreen = styled(Wrapper)`
    height: 100vh;
    width: 100vw;
    background-color: red;
`;

export const ErrorLarge = styled.h1`
    color: white;
    font-size: 72px;
`;
