// @flow
import { message } from 'antd';
import { setIn } from 'formik';
import * as R from 'ramda';
import moment from 'moment';

/**
 * MEMOIZED
 */
export const isValidJSON = R.memoize((JSONString: string): boolean => {
    try {
        JSON.parse(JSONString);
        return true;
    } catch (err) {
        return false;
    }
});

/**
 * MEMOIZED
 */
export const toCamelCase = R.memoize((stringToTransform: string): string =>
    stringToTransform.replace(/^([A-Z])|[\s-_]+(\w)/g, (match, p1, p2) => {
        if (p2) return p2.toUpperCase();
        return p1.toLowerCase();
    })
);

/**
 * @see https://stackoverflow.com/a/16655847
 */
export const isNumeric = (n: any): boolean => Number(parseFloat(n)) === n;

/**
 * @description utility function to map object properties into array
 *
 * @example:
 *  mapObjectToPairs(
 *      {
 *          'key1': {
 *              value: '1'
 *          },
 *          'key2': {
 *              value: '2'
 *          }
 *      },
 *      'value'
 *  );
 * -> { 'key1': '1', 'key2': '2' }
 */
export const mapObjectToPairs = (object: Object, pathToValue: string) => {
    if (typeof object === 'object') {
        return Object.entries(object).reduce(
            (acc: Object, property: any) => ({
                ...acc,
                [property[0]]: property[1][pathToValue],
            }),
            {}
        );
    }

    return null;
};

export const generateShortKey = () =>
    Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);

/**
 * @description creates and returns object with methods to show and hide loading message
 *
 * @example:
 *  loadingMessage("Loading...");
 * -> {show: () => void, hide: () => void}
 */
export const loadingMessage = (content: string) => {
    const key = generateShortKey();

    return {
        show: () => {
            message.loading({
                key,
                content,
            });
        },
        hide: () => {
            message.destroy(key);
        },
    };
};

/**
 * @description returns a "view" of the given data structure, determined by the given path
 *
 * @example:
 *  viewPath(["a", 0, "c"], { a: [ { c: 1 } ] } );
 * -> 1
 */
export const viewPath = (path: Array<string | number>, obj: GenericObject) =>
    R.view(R.lensPath(path), obj);

/**
 * @description returns the result of "setting" the portion of the given data structure focused by the given path
 *
 * @example:
 *  setPath(["a", 0, "c"], 0, { a: [ { c: 1 } ] });
 * -> { a: [ { c: 0 } ] }
 */
export const setPath = (path: Array<string | number>, value: any, obj: GenericObject) =>
    R.set(R.lensPath(path), value, obj);

/**
 * @description formats word to title form
 *
 * @example:
 *  wordToTitle("title");
 * -> "Title"
 */
export const wordToTitle = (word: string): string => word.charAt(0).toUpperCase() + word.slice(1);

/**
 * @deprecated
 *
 * @description omits Apollo's object properties from whole object recursively
 */
export const omitTypename = (values: any): any => {
    if (Array.isArray(values)) {
        return values.map(omitTypename);
    }

    if (values !== null && typeof values === 'object') {
        return Object.entries(values).reduce((acc, [key, value]) => {
            if (key === '__typename') {
                return acc;
            }

            if (value !== null && typeof value === 'object') {
                return {
                    ...acc,
                    [key]: omitTypename(value),
                };
            }

            return {
                ...acc,
                [key]: value,
            };
        }, {});
    }

    return values;
};

/**
 * MEMOIZED
 */
export const getAdminPortalVersion = R.memoize(() => {
    try {
        const packageJson = require('../../../package.json'); // eslint-disable-line global-require

        return packageJson.version;
    } catch (err) {
        console.error(err);

        return 'N/A';
    }
});

export const transformYupErrorToObject = (yupError: any) => {
    let errors: any = {};

    if (yupError.inner.length === 0) {
        return setIn(errors, yupError.path, yupError.message);
    }

    for (const err of yupError.inner) {
        if (!errors[err.path]) {
            errors = setIn(errors, err.path, err.message);
        }
    }

    return errors;
};

export const isLocalBuild = () => process.env.NODE_ENV === 'development';
export const isProductionBuild = () => process.env.NODE_ENV === 'production';
export const isDevelopment = () => process.env.RAZZLE_ENVIRONMENT === 'development';
export const isStaging = () => process.env.RAZZLE_ENVIRONMENT === 'staging';
export const isProduction = () =>
    ['production', 'demo', 'staging'].includes(process.env.RAZZLE_ENVIRONMENT);

export const inputMomentFormatter = (datelike: any) => {
    if (!datelike || moment.isMoment(datelike)) return datelike;
    return moment(datelike);
};
