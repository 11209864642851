// @flow
import { QUERY_USER_DATA } from './queries';

export const defaults = {
    userData: {
        __typename: 'UserData',
        jwt: null,
    },
};

// $FlowFixMe
const updateUserData = (_, { jwt }, { cache }) => {
    const data = {
        userData: {
            __typename: 'UserData',
            jwt: jwt || null,
        },
    };
    cache.writeQuery({ query: QUERY_USER_DATA, data });

    return null;
};

export const resolvers = {
    Mutation: {
        updateUserData,
    },
};
