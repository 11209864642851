import loadable from '@loadable/component';

export const Root = loadable(() => import(/* webpackChunkName: "root" */ './pages'));

export const LoginPage = loadable(() => import(/* webpackChunkName: "login" */ './pages/Login'));

export const NotFound = loadable(
    () => import(/* webpackChunkName: "notFound" */ './pages/NotFound')
);

export const ForgottenPassword = loadable(
    () => import(/* webpackChunkName: "forgottenPassword" */ './pages/ForgottenPassword')
);
